/* eslint-disable no-useless-escape */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import Link from 'next/link';
import cx from 'classnames';
import Popover from '@common_popover';
import dynamic from 'next/dynamic';
import { COLORS } from '@theme_vars';
import useMediaQuery from '@hook/useMediaQuery';
import { getCartId, setCartId } from '@helper_cartid';
import { useQuery } from '@apollo/client';
import { getCartIdUser } from '@core_modules/cart/services/graphql/schema';
import {
    mergeCart as mutationMergeCart, socialLogin,
} from '@core_modules/login/services/graphql';
import { expiredToken, features, customerTokenKey } from '@config';
import firebase from 'firebase';
import { setLogin, setHavePhoneNumber } from '@helper_auth';

const Login = dynamic(() => import('@core_modules/theme/components/header/components/v1/adaptive/plugin/loginform'), { ssr: true });

const UserInfo = (props) => {
    const { t, isLogin, PopoverContent, openState, handleDispatch, app_cookies, dataMenu, deviceType, frontendOptions, i18n,
        lastPathNoAuth, query, removeDecimalConfig, storeConfig, tReady, withAuth, _nextI18Next } = props;

    const { isMobile } = useMediaQuery();

    const loginRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    let cartId = '';
    if (typeof window !== 'undefined') {
        cartId = getCartId();
    }

    const cartData = useQuery(getCartIdUser, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
        skip: !isLogin,
    });

    const [mergeCart] = mutationMergeCart();

    const expired = storeConfig?.oauth_access_token_lifetime_customer
        ? new Date(Date.now() + parseInt(storeConfig?.oauth_access_token_lifetime_customer, 10) * 3600000)
        : expiredToken;

    // handle Merge Cart
    React.useEffect(() => {
        if (cartData.data && cartData.data.customerCart && cartData.data.customerCart && cartData.data.customerCart.id) {
            const custCartId = cartData.data.customerCart.id;
            if (cartId !== custCartId) {
                window.backdropLoader(true);
                mergeCart({
                    variables: {
                        sourceCartId: cartId,
                        destionationCartId: custCartId,
                    },
                })
                    .then(async (res) => {
                        await setCartId(res.data.mergeCarts.id, expired);
                        window.backdropLoader(false);
                    })
                    .catch(() => {
                        window.backdropLoader(false);
                    });
            }
        }
    }, [cartData]);

    const LoginProps = {
        t,
        app_cookies,
        dataMenu,
        deviceType,
        frontendOptions,
        i18n,
        lastPathNoAuth,
        query,
        removeDecimalConfig,
        storeConfig,
        tReady,
        withAuth,
        _nextI18Next,
        handleDispatch,
        open,
        withFirebase: false,
    };

    const LoginContent = () => (
        <div
            className={cx(
                'py-[25px]', 'px-5', 'min-h-fit', 'max-w-[308px]', '!rounded-[5px]', 'popover-active',
                'w-[350px]',
            )}
        >
            <Login
                {...LoginProps}
            />
        </div>
    );

    const [actSocialLogin] = socialLogin();

    // Listen to the Firebase Auth state and set the local state.
    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        if (!isLogin) {
            if (!firebase.apps.length) {
                firebase.initializeApp(features.firebase.config);
            } else {
                firebase.app(); // if already initialized, use that one
            }
            if (features.firebase.config.apiKey !== '') {
                try {
                    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
                        if (firebase.auth().currentUser) {
                            handleDispatch({ type: 'CLOSE_ACCOUNT' });
                            const fullname = user.displayName.split(' ');
                            const firstName = fullname[0];
                            let lastName = '';
                            const { email } = user;
                            fullname.forEach((entry) => {
                                // eslint-disable-next-line eqeqeq
                                if (entry != firstName) {
                                    lastName += `${entry} `;
                                }
                            });
                            firebase
                                .auth()
                                .currentUser.getIdToken(true)
                                .then((u) => {
                                    window.backdropLoader(true);
                                    actSocialLogin({
                                        variables: {
                                            email,
                                            socialtoken: u,
                                            firstname: firstName,
                                            lastname: lastName,
                                        },
                                    })
                                        .then(async (res) => {
                                            const have_phone_number = res.data.generateCustomerTokenSocialLogin.havePhoneNum || false;
                                            handleDispatch({ type: 'CLOSE_ACCOUNT' });
                                            setLogin(1, expired);
                                            const customerToken = res.data.generateCustomerTokenSocialLogin.token;
                                            localStorage.setItem(`${customerTokenKey}`, customerToken);
                                            setHavePhoneNumber(have_phone_number, expired);
                                            window.backdropLoader(false);
                                            window.toastMessage({ open: true, variant: 'success', text: t('common:login:success') });
                                        })
                                        .catch((e) => {
                                            window.backdropLoader(false);
                                            window.toastMessage({
                                                open: true,
                                                variant: 'error',
                                                text: e.message.split(':')[0] || t('common:login:failed'),
                                            });
                                        });
                                });
                        }
                    });
                    return () => unregisterAuthObserver();
                } catch {
                    return () => {};
                }
            }
        }
    }, []);

    const active = true;

    return (
        <>
            {isLogin ? (
                <div
                    onMouseEnter={() => {
                        if (!openState.isAccountOpen && !isMobile) {
                            handleDispatch({ type: 'OPEN_ACCOUNT' });
                        }
                    }}
                    // onMouseLeave={() => setOpen(false)}
                    className={cx('myaccount-menu')}
                >
                    <Popover
                        content={<PopoverContent />}
                        open={openState.isAccountOpen}
                        setOpen={() => {}}
                        className={cx('w-[160px]', 'top-[36px]', 'right-[-80px]')}
                        contentClassName={cx('!rounded-[5px] !border-neutral-100 shadow-type-1 max-h-fit')}
                    >
                        <div className="popover-children flex flex-row">
                            <Link href="/customer/account" className={cx('swift-login-register-link')}>
                                <span className="customer-icon text-neutral-black hover:before:text-yellow-300" />
                            </Link>
                        </div>
                    </Popover>
                </div>
            ) : (
                <div
                    // onMouseEnter={() => {
                    //     if (!isMobile) {
                    //         setOpen(true);
                    //     }
                    // }}
                    onMouseLeave={() => setOpen(false)}
                    className={cx('myaccount-menu')}
                    ref={loginRef}
                >
                    <Popover
                        content={!isMobile ? <LoginContent /> : null}
                        open={active}
                        setOpen={setOpen}
                        className={cx(
                            'w-[160px]', 'top-[37px]', 'right-[-80px]',
                            'hidden desktop:group-hover/account-icon:block',
                            'after: after:w-10 after:h-10 after:absolute after:top-[-10px] after:right-[75px]',
                        )}
                        contentClassName={cx('!rounded-[5px] !border-neutral-100 shadow-type-1 max-h-fit')}
                        wrapperClassName="group/account-icon"
                        // visibleContent
                    >
                        <div className="popover-children flex flex-row">
                            <Link href="/customer/account/login" className={cx('swift-login-register-link')}>
                                <span className="customer-icon text-neutral-black hover:before:text-yellow-300" />
                            </Link>
                        </div>
                    </Popover>
                </div>
            )}
            <style jsx global>
                {`
                    .customer-icon:before {
                        display: block;
                        content: "\e933" !important;
                        font-family: var(--icons-urbanicon-fossil-theme);
                        font-size: 1.75rem;
                        line-height: 1.75rem;
                        width: 1.75rem;
                        height: 1.75rem;
                    }
                    .popover-active:after {
                        content: '';
                        background: #fff;
                        border-bottom: 1px solid ${COLORS.neutral[100]};
                        border-right: 1px solid ${COLORS.neutral[100]};
                        position: absolute;
                        right: 86px;
                        top: -7px;
                        width: 14px;
                        height: 14px;
                        transform: rotate(225deg);
                    }
                    @media only screen and (max-width: 767.9px) {
                        .customer-icon:before {
                            font-size: 25px;
                            line-height: 25px;
                            width: 25px;
                            height: 25px;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default UserInfo;
